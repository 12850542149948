<template>
  <div id="app">
      <Navigation />
      <router-view></router-view>
      <Footer />
  </div>
</template>

<script>
import Navigation from "./components/Navigation.vue"
import Footer from "./components/Footer.vue"
export default {
  name: 'App',
  components: {
      Navigation,
      Footer
  }
}
</script>

<style scoped>
  *{
      margin: 0px;
      padding:0px;
      background-color:#F8F9FD;
  }
  #app {
      font-family: 'Open Sans',  Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin: 0px;
      padding: 0px;
      display: block;
      overflow: hidden;
  }

</style>
