<template>
    <div>
        <Header text="Blog" :image="require('@/assets/gallery/machove-steny/01.jpg')" />
        <div v-if="Object.keys(article).length !== 0" class="wrapper">
            <div class="heading">
                <div class="title">
                    <h2>
                        {{ article.title }}
                    </h2>
                </div>
                <div class="date">
                    {{ article.pubDate | moment('DD.MM.YYYY HH:mm:ss')}}
                </div>
            </div>
            <div class="content" v-html="article.content" />
            <div class="medium">
                <a :href="article.link" target="_blank">
                    <img src="../assets/blog/medium-logo.svg" alt="medium-logo" />
                </a>
            </div>
        </div>
        <div class="empty-article" v-else>
            Článok sa nenašiel!
        </div>
        
    </div>
</template>
<script>
    import Header from "../components/Header"
    import axios from "axios"
    export default {
        name: 'Article',
        components: {
            Header,
        },
        data() {
            return {
                id: this.$route.params.id,
                loading: false,
                image: require('@/assets/blog/post-1/01.jpg'),
                article: {}
            }
        },
        methods: {
            getArticles() {
                this.loading = true;
                axios.get('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@k2flora.sk')
                .then(response => {

                    this.article = response.data.items.filter(article => {
                        const articleId = article.guid.substring(article.guid.lastIndexOf('/')+1, article.length)
                        if(articleId == this.id) return article
                    })[0];
                    this.loading = false;
                })
            },
        },
        mounted() {
            this.getArticles();
        },
        created(){
            if(Object.keys(this.article).length !== 0) {                
                document.title = this.article.title+' - Blog | k2flora'
            }
            else {
                document.title = 'Blog | k2flora'
            }            
        }
    }
</script>
<style scoped>
    .wrapper{
        width: 940px;
        margin: 0 auto;
    }
    .heading{
        background-color: #000000;
        margin-top: 25px;
        padding: 25px 20px;
        color: #fff;
    }
    .content{
        padding: 25px 20px;
        margin: 0px;
        text-align: justify;
        font-size: 18px;
        line-height: 28px;
    }
    .content >>> img {
        width: 100%;
    }
    .content >>> a {
        color: black;
    }
    .content >>> blockquote {
        padding-left: 20px;
        border-left: 3px solid #349937;
    }
    .medium{
        display: block;
        margin: 0 auto;
        width: 100px;
    }
    .empty-article {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60vh;
        font-size: 32px;
        color: #a6a6a6;
    }
    @media screen and (max-width: 980px) {
        .wrapper{
            width: auto;
        }
    }

</style>
