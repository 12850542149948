<template>
    <div class="wrapper">
        <div v-if="slides.length > 0">
            <splide :options="primaryOptions" ref="primary">
                <splide-slide v-for="slide in slides" :key="slide.id">
                    <div class="slide-inner" :style="{backgroundImage:  'url(' + slide.attributes.image.data.attributes.url + ')'}">
                        <div class="overlay"></div>
                        <div class="content">
                            <a :href="slide.attributes.link"><h1>{{ slide.attributes.name }}</h1></a>
                            <div class="description">
                                <p>{{ slide.attributes.description }}</p>
                            </div>
                        </div>
                    </div>
                </splide-slide>
            </splide>
        </div>
        <div class="loading" v-else>
            <img src="../assets/loading.svg">
        </div>
    </div>
</template>
<script>
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import '@splidejs/splide/dist/css/themes/splide-default.min.css';
    import { mapGetters } from "vuex";
    import axios from 'axios';
    export default {
        name: 'Home',
        components: {
             Splide,
             SplideSlide
        },
        data() {
            return {
                primaryOptions: {
                    autoplay: true,
                    pauseOnHover: false,
                    speed: 2000,
                    arrows: false,
                    type: 'loop',
                    width: '100%',
                    height: '100vh',
                    perPage: 1,
                    perMove: 1,
                    pagination: false,
                    lazyLoad: 'nearby',
                    preloadPages: 12
                },
                slides: [],
            }
        },
        computed:{
            ...mapGetters({
                carousel: 'home/getCarousel',
            }),
        },
        methods: {
            getSlides() {
                axios.get('https://k2flora-cms.herokuapp.com/api/homes?populate=*&sort=id:asc')
                .then(response => {
                    this.slides = response.data.data;
                    console.log(this.slides);
                });
            },
            getResolutionImg(sizes) {
                if(sizes.large) {
                    return sizes.large.url;
                }
                return sizes.medium.url;
            }
        },
        created(){
            this.getSlides();
            document.title = 'k2flora'
        }
    }
</script>
<style scoped>

    .loading {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
    .wrapper{
        width:100%;
        height: 100vh;
        position: relative;
        top:0;
        left:0;
    }
    .wrapper a{
        color: #fff;
        text-decoration: none;
    }
    .wrapper a:focus{
        color: #fff;
    }
    .slide-inner{
        position: relative;
        width:auto;
        height:100vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    .slide-inner h1{
        font-size: 64px;
        line-height: 84px;
        font-weight: 600;
        color: #fff;
    }
    .overlay{
        position: absolute;
        width: 100%;
        height: 100vh;
        z-index: 1;
        background: rgba(0, 0, 0, 0.3);
    }
    .content{
        position: absolute;
        left: 0px;
        top: 20%;
        padding: 50px;
        height:100vh;
        z-index: 2;
    }
    .description{
        max-width: 600px;
        background-color: rgba(52, 153, 55, 0.75);
        padding: 10px;
    }
    .description p{
        color: #fff;
        font-size: 15px;
    }
    @media screen and (max-width: 720px) {
        .content{
            top:20%;
            padding: 20px;
        }
        .slide-inner h1{
            font-size: 36px;
            line-height: 44px;
        }
        .description{
            max-width: none;
            width: auto;
        }
    }

</style>
