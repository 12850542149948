<template>
    <div class="wrapper" :style="{ background : color }">
        <div class="wrapper-inner">
            <div class="heading">
                <div class="name">
                   <router-link :to="url">
                        <h2>{{ heading }}</h2>
                   </router-link>

                </div>
                <div class="date">
                    {{ date }}
                </div>
            </div>
            <div class="content">
                <p v-html="text"></p>
            </div>
                    <router-link :to="url">
                    <button class="button">
                        Prečítať viac
                    </button>
                    </router-link>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Post',
        props:{
            heading: String,
            text: String,
            date: String,
            url: String,
            color: String
        }
    }
</script>
<style scoped>
    .wrapper{
        width: auto;
        padding: 40px 20px;
    }
    .wrapper-inner{
        width: 940px;
        margin: 0 auto;
    }
    .heading{
        display: flex;
        align-items: center;
    }
    .warning{
        color: red;
    }
    .name{
        margin-right: 20px;
        text-decoration: underline;
    }
    .name a, a:visited{
        color: #000000;
    }
    .name a:hover{
        text-decoration: none !important;
    }

    .date{
        margin-left: auto;
        color: #434343;
        font-size: 14px;
        line-height: 22px;
        font-style: italic;
    }
    .content{
        line-height: 28px;
    }
    .button{
        border: none;
        background: #349937;
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        padding: 12px 22px;
        margin-top: 20px;
        cursor: pointer;
    }

    @media screen and (max-width: 960px) {
        .wrapper-inner{
            width: auto;
        }
    }

</style>
