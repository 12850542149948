<template>
    <div>
        <Header text="Blog" :image="require('@/assets/gallery/machove-steny/01.jpg')" />
        <div class="wrapper">
            <div class="heading">
                <div class="title">
                    <h2>
                        Korunou krásy je strecha
                    </h2>
                </div>
                <div class="date">
                    26.06.2021
                </div>
            </div>
            <div class="content idea-section">
                <p>
                    <i>
                        Plochá, šikmá, oblúková. Nech je akákoľvek, je neodmysliteľnou súčasťou stavieb. Chráni
                        vnútorné priestory pred vonkajšími vplyvmi, odvádza zrážkovú vodu a slúži ako technický
                        priestor pre rôzne inštalácie. Strecha má však oveľa väčší potenciál! U nás sme sa rozhodli,
                        že strechám dáme nový význam, a to, čo sa zdá byť netradičné, premeníme na štandard.
                    </i>
                </p>
            </div>
            <div class="content">
                <p>
                    S čoraz viac otepľujúcou sa klímou a zmenami, ktoré spôsobilo ľudstvo samo, prichádzajú do našich
                    zemepisných šírok teplotné a zrážkové výkyvy. S meniacou sa klímou je potrebné sa adaptovať a
                    zmeniť aj naše návyky. Správne zmeny v našom myslení totižto dokážu spomaliť, ak nie rovno zastaviť,
                    hrozivé scenáre klimatických zmien.
                </p>
                <p>
                    Práve obnovenie životného prostredia, vyčistenie ovzdušia a zvýšenie produkcie kyslíka, čo
                    priamoúmerne znižuje hladinu oxidu uhličitého v atmosfére, sú kľúčové faktory, ktorým sa budeme
                    venovať na príklade a realizácii vegetačných striech. V K2flore sme stavili na profesionalitu,
                    odbornosť a maximálne úsilie priblížiť vegetačné strechy širokému spektru ľudí, a tým prispieť
                    svojou troškou k ich aktívnej implementácii. Pretože s nimi je život a naše prostredie oveľa
                    zdravšie.
                </p>
                <div class="image" :style="{ backgroundImage : 'url('+image1+')' }">
                </div>
                <p>
                    Vegetačné strechy sú spomínané už v staroveku a ich história plynule pokračuje cez antiku až po
                    novovek. Nie sú žiadnym novotvarom ani fenoménom dnešnej doby. Len sa na ne akoby medzičasom
                    pozabudlo a ich krásu a funkčnosť znovu objavujeme.
                </p>
                <p>
                    O dôležitosti vegetačných striech hovoria samotné dejiny. Visuté záhrady kráľovnej Semiramis (z
                    približne 8. storočia p. n. l.) patria medzi jeden zo siedmich divov sveta napriek tomu, že sa do
                    dnešnej doby nezachovali.
                </p>
                <p>
                    Zelené strechy sú atraktívnym a výrazným architektonickým prvkom stavieb. Do prostredia, v ktorom
                    sa nachádzajú, prinášajú široké spektrum pozitívnych aspektov ako napríklad:
                </p>
                <ul>
                    <li><p>Zlepšujú mikroklímu prostredia.</p></li>
                    <li><p>Zvlhčujú vzduch, čím priaznivo ochladzujú prostredie v ich okolí.</p></li>
                    <li><p>Sú prirodzeným absorbérom prachových častíc.</p></li>
                    <li><p>Tlmia hluk a zlepšujú tak akustickú pohodu v ich okolí.</p></li>
                    <li><p>Pohlcujú oxid uhličitý a, naopak, produkujú kyslík.</p></li>
                    <li><p>Zadržiavajú dažďovú vody, čím priaznivo odľahčujú stokové systémy miest a obcí.</p></li>
                    <li><p>Sú dokonalým izolantom strešného plášťa. Napomáhajú k zníženiu nákladov na vykurovanie
                        a chladenie budov.</p></li>
                    <li><p>Pri správnej a funkčnej realizácii predlžujú životnosť strešnej konštrukcie ako celku,
                        čím významne znižujú náklady na obnovu budov.</p></li>
                </ul>
                <p>
                    Na naše územie sa vegetačné strechy opäť prinavrátili v 19. storočí. Odvtedy viac, či menej pribúda
                    ich implementácia do stavieb. Vegetačné strechy sú neoddeliteľnou súčasťou urbanistického priestoru.
                    Vytvárajú zaujímavé a hodnotné priestory.
                </p>
                <p>
                    Ich realizácia nemá takmer žiadne limity. Vhodné sú pre všetky typy striech, od plochých, cez šikmé
                    až po atypické tvary striech škrupín, hyperbolických paraboloidov, či oblúkových striech.
                </p>
                <p>
                    Z hľadiska typu vegetačnej výsadby a výšky substrátu sa rozdeľujú na:
                </p>
                <ul>
                    <li><p>extenzívne,</p></li>
                    <li><p>polointenzívne a</p></li>
                    <li><p>intenzívne.</p></li>
                </ul>
                <p>
                    <b>
                        Extenzívne vegetačné strechy
                    </b>
                </p>
                <p>
                    Výška substrátu extenzívnej vegetačnej strechy je do 150 mm, v prípade výsadby krov, substrát musí
                    spĺňať výšku od 150–200 mm. Extenzívna zelená strecha môže byť realizovaná aj na konštrukciách s
                    nižšou únosnosťou do 300 kg/m2. Výber vegetácie extenzívnej zelenej strechy tvoria prevažne suché
                    trávy, rozchodníky, skalničky a nízke kríky a pod. Tento druh zelenej strechy nie je určený pre
                    bežný pohyb osôb, plní ekologickú, estetickú a izolačnú funkciu. Extenzívne zelené strechy sú menej
                    náročné na údržbu. Strechy tohto typu väčšinou nepotrebujú zavlažovanie a dokážu akumulovať vodu.
                    Pri dlhšie trvajúcom suchu a vysokých teplotách sa závlaha, aspoň manuálna, odporúča. Vegetácia sa
                    navrhuje tak, aby sa dokázala rozrastať aj sama. Extenzívne vegetačné strechy nie sú na realizáciu
                    finančne náročné.
                </p>
                <div class="image" :style="{ backgroundImage : 'url('+image2+')' }">
                </div>
                <p>
                    Vedeli ste, že:
                </p>
            </div>
            <div class="content idea-section">
                <p>
                    <i>
                        Účinnosť fotovoltaických elektrární na zelených strechách exponenciálne rastie. Práve zeleň
                        okolo fotovoltaických článkov odparovaním vody prirodzene ochladzuje vzduch nad strechou, a
                        tým priaznivo vplýva na chod solárnej elektrárne, zvyšuje jej účinnosť a šetrí viac vaše
                        financie.
                    </i>
                </p>
                <p>
                    <i>
                        Tzv. „zelená“ strecha nemusí byť len zelená. Krásnym príkladom sú práve extenzívne strechy.
                        Rôznorodosť druhov nenáročných sukulentov, ktoré tvoria druhovú výsadbu na extenzívnych
                        strechách v priebehu roka, mení svoju farbu, a tak strecha doslova hrá farbami.
                    </i>
                </p>
                <p>
                    <i>
                        Pri orientácii na veľmi slnečné strany je závlaha na mieste. Platí to najmä pri strechách
                        orientovaných a juh a západ. Tieto strechy sú viac namáhané teplotnými rozdielmi, vetrom a
                        priamym žiarením. Pri dlhšie trvajúcom teple a období bez zrážok sa preto odporúča rastlinkám
                        pomôcť, a strechu poliať.
                    </i>
                </p>
            </div>
            <div class="content">
                <p>
                    <b>
                        Intenzívne vegetačné strechy
                    </b>
                </p>
                <p>
                    Absolútnym lídrom v „zelených“ strechách sú intenzívne vegetačné strechy. Technicky náročnejšia
                    realizácia však prináša doslova dych berúci priestor. Intenzívne strechy sú príroda na dosah. Je to
                    vlastný park, či záhrada priamo nad vašimi hlavami, ktorá okrem toho, že prináša priestor na relax,
                    aktívny odpočinok, priamo spolupôsobí so stavbou a jej vnútorným prostredím. Intenzívne strechy
                    prinášajú so sebou úspory na vykurovaní a chladení budov, ktorých hodnoty môžu dosahovať aj viac
                    ako 300 % úspory.
                </p>
                <p>
                    Intenzívne zelené strechy sú atraktívnym priestorom. Svoje miesto si u nás našli na každom
                    zaujímavom projekte biznis centra, či nákupného centra. Ich potenciál bol objavený. Stali sa
                    pútačom a lákadlom pre zákazníkov, ktorých lákajú na posedenie či kávičku.
                </p>
                <div class="image" :style="{ backgroundImage : 'url('+image3+')' }">
                </div>
            </div>
            <div class="content">
                <p>
                    Vedeli ste, že:
                </p>
            </div>
            <div class="content idea-section">
                <p>
                    <i>
                        Intenzívna zelená strecha sa realizuje s plne automatickým závlahovým systémom. Ten je účelovo
                        nadstavený na optimalizáciu nákladov na závlahu, pričom musí zohľadňovať aj druhovú výsadbu na
                        danom mieste.
                    </i>
                </p>
                <p>
                    <i>
                        Váha intenzívnej zelenej strechy sa pohybuje okolo 1 500 kg/m2. Vzhľadom na jej váhu, je to typ
                        strechy, ktorý je potrebné, a aj vhodné plánovať už v projekčných začiatkoch stavby.
                    </i>
                </p>
                <p>
                    <i>
                        Dokážu ochladiť vzduch až o niekoľko °C, a tým vytvoriť príjemné prostredie aj v horúcich
                        letných dňoch. Odparujúca sa voda ochladzuje a zvlhčuje vzduch v okolí vegetačnej strechy.
                        Takéto prostredie praje všetkým živým organizmom najmä v horúcich dňoch.
                    </i>
                </p>
                <p>
                    <i>
                        Svojou konštrukčnou skladbou a výškou substrátu (okolo 300–800 mm) však tvoria dokonalú
                        izoláciu a napomáhajú k zníženiu prevádzkových nákladov budov na vykurovanie a chladenie.
                    </i>
                </p>
            </div>
            <div class="content">
                <p>
                    Vegetačné strechy sú najmä o rastlinách, ich rôznorodosti a druhovej pestrosti. Vďaka čitateľnej
                    výsadbe dokážu vytvoriť okúzľujúci a harmonický priestor. Rastliny vysadené na vegetačných
                    strechách sú však <b>vystavované extrémnym</b> podmienkam ako napríklad priamemu intenzívnemu žiareniu,
                    striedaniu teplotných rozdielov, silnému vetru a mrazu či lokálne nadmernému úhrnu zrážok. Pri ich
                    výbere je nutné mať po ruke odborníka, ktorý sa rozumie nielen stavebným konštrukciám, ale aj
                    botanike, floristike a záhradnej architektúre.
                </p>
                <p>
                    K2flora je centrum, ktoré spája tých najlepších odborníkov do projekčných a realizačných tímov, a
                    to s jediným cieľom. Úspešne zrealizovať funkčnú vegetačnú strechu, ktorá bude svojmu účelu slúžiť
                    desiatky rokov bez potreby zásahu do systému strechy.
                </p>
            </div>
        </div>
        <div class="medium">
            <a href="https://medium.com/@k2projekt.sk/korunou-kr%C3%A1sy-je-strecha-24c7b9b34a97" target="_blank">
                <img src="../../assets/blog/medium-logo.svg" alt="medium-logo" />
            </a>
        </div>
    </div>
</template>
<script>
    import Header from "../../components/Header"
    export default {
        name: 'Post3',
        components: {
            Header,
        },
        data() {
            return {
                image1: require('@/assets/blog/post-3/01.jpeg'),
                image2: require('@/assets/blog/post-3/02.png'),
                image3: require('@/assets/blog/post-3/03.jpg'),
            }
        },
        created(){
            document.title = 'Korunou krásy je strecha - Blog | k2flora'
        }
    }
</script>
<style scoped>
    .wrapper{
        width: 940px;
        margin: 0 auto;
    }
    .heading{
        background-color: #000000;
        margin-top: 25px;
        padding: 25px 20px;
        color: #fff;
    }
    .content{
        padding: 25px 20px;
        margin: 0px;
        text-align: justify;
        font-size: 18px;
        line-height: 28px;
    }
    .idea-section{
        border-left: 3px solid #349937;
        margin-top:25px;
    }
    .image{
        width: 100%;
        height: 70vh;
        background-size: cover;
        background-position: center center;
    }
    .medium{
        display: block;
        margin: 0 auto;
        width: 100px;
    }
    @media screen and (max-width: 980px) {
        .wrapper{
            width: auto;
        }
    }

</style>
