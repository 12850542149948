<template>
    <div class="header" :style="{ backgroundImage: 'url('+image+')' }">
        <div class="header-inner">
            <h2>{{ text }}</h2>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Header',
        props:{
            text: String,
            image: String
        }
    }
</script>
<style scoped>
    .header{
        width: 100%;
        height: 300px;
        box-sizing: border-box;
        padding-top: 80px;
        background-size: cover;
        background-position: center center;
    }
    .header-inner{
        max-width: 600px;
        padding: 10px 20px 20px;
        background-color: rgba(52, 153, 55, 0.75);
        margin: 0 auto;
        text-align: center;
        color: #fff;
    }
</style>
