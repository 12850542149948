<template>
  <nav class="navigation-container" v-bind:class="compareActive()">
    <div class="hamburger" @click="active = !active">
      <font-awesome-icon v-if="!active" icon="bars" size="lg" />
      <font-awesome-icon v-if="active" icon="times" size="lg" />
    </div>
    <div class="navigation">
      <div @click="closeNav">
        <router-link to="/">
          <a class="navigation-link" style="padding: 3px 0">
            <div class="logo-style"></div>
          </a>
        </router-link>
      </div>
      <div @click="closeNav">
        <router-link to="/hodnoty">
          <a class="navigation-link"> Hodnoty </a>
        </router-link>
      </div>
      <div @click="closeNav">
        <router-link to="/produkty">
          <a class="navigation-link"> Produkty </a>
        </router-link>
      </div>
      <div @click="closeNav">
        <router-link to="/galeria">
          <a class="navigation-link"> Galéria </a>
        </router-link>
      </div>
      <div @click="closeNav">
        <router-link to="/blog">
          <a class="navigation-link"> Blog </a>
        </router-link>
      </div>
      <div @click="closeNav">
        <router-link to="/partneri">
          <a class="navigation-link"> Partneri </a>
        </router-link>
      </div>
      <div @click="closeNav">
        <router-link to="/kontakt">
          <a class="navigation-link"> Kontakt </a>
        </router-link>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "Navigation",
  data() {
    return {
      active: false,
    };
  },
  methods: {
    compareActive: function () {
      if (this.active) return "show-nav";
      else return "";
    },
    closeNav: function () {
      this.active = false;
    },
  },
};
</script>
<style scoped>
.navigation a {
  text-decoration: none;
}

.navigation-container {
  width: 100%;
  background-color: #000;
  color: white;
  padding: 5px 0px;
  position: relative;
  max-height: 50px;
  overflow-y: hidden;
  transition: all 0.4s ease-out;
}

.navigation-link {
  display: block;
  padding: 10px 37px;
  color: #fff;
  line-height: 22px;
  text-align: left;
  text-decoration: none;
}

.shop {
  background-color: #349937;
}

.logo-style {
  width: 88px;
  height: 42px;
  margin-left: 37px;
  background: url("../assets/logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
}
.hamburger {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 25px;
  height: 25px;
  display: block;
  color: white;
  cursor: pointer;
  opacity: 1;
  transition: all 0.4s ease-out;
}
.show-thumb {
  opacity: 0;
}
.show-nav {
  height: auto;
  max-height: 3000px;
}
@media (min-width: 1054px) {
  .navigation {
    display: flex;
    align-content: center;
    width: fit-content;
    margin: 0 auto;
    text-decoration: none;
  }
  .hamburger {
    display: none;
  }
  .navigation-link {
    padding: 15px 37px;
    display: inline-block;
    flex-basis: 100%;
    text-decoration: none;
  }
}
</style>
