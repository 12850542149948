import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import Contact from "../views/Contact.vue";
import Products from "../views/Products.vue";
import Product from "../views/Product.vue";
import Partners from "../views/Partners";
import Galeria from "../views/Galeria";
import Blog from "../views/Blog";
import Post1 from "../views/posts/Post1";
import Post2 from "../views/posts/Post2";
import Post3 from "../views/posts/Post3";
import Hodnoty from "../views/Hodnoty";
import GDPR from "../views/GDPR";
import Article from "../views/Article";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/produkty",
        name: "Products",
        component: Products
    },
    {
        path: "/produkt/:id",
        name: "Product",
        component: Product
    },
    {
        path: "/kontakt",
        name: "Contact",
        component: Contact
    },
    {
        path: "/partneri",
        name: "Partners",
        component: Partners
    },
    {
        path: "/galeria",
        name: "Galeria",
        component: Galeria
    },
    {
        path: "/blog",
        name: "Blog",
        component: Blog
    },
    {
        path: "/post/:id",
        name: "Post",
        component: Article,
        props: true
    },
    {
        path: "/post-1",
        name: "Post-1",
        component: Post1
    },
    {
        path: "/post-2",
        name: "Post-2",
        component: Post2
    },
    {
        path: "/post-3",
        name: "Post-3",
        component: Post3
    },
    {
        path: "/hodnoty",
        name: "Hodnoty",
        component: Hodnoty
    },
    {
        path: "/gdpr",
        name: "GDPR",
        component: GDPR
    },
];


const router = new VueRouter({
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
