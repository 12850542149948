<template>
    <div class="gallery">
        <div class="gallery-description" v-if="text">
            {{ text }}
        </div>
        <splide :options="primaryOptions">
            <splide-slide v-for="slide in images" :key="slide">
                <div class="slide-inner" :style="{backgroundImage:  'url(' + slide + ')'}">
                </div>
            </splide-slide>
        </splide>
    </div>
</template>
<script>
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import '@splidejs/splide/dist/css/themes/splide-default.min.css';
    export default {
        name: 'Gallery',
        components: {
            Splide,
            SplideSlide,
        },
        props:{
            text: String,
            images: Array,
        },
        data(){
            return {
                primaryOptions: {
                    type: 'slide',
                    width: '100%',
                    lazyLoad: 'nearby',
                    preloadPages: 4
                },
            }
        }
    }
</script>
<style scoped>
    .gallery{
        width: auto;
        position: relative;
    }
    .gallery-description{
        position: absolute;
        top: 80px;
        right: 30px;
        max-width: 600px;
        width: 60%;
        background-color: rgba(52, 153, 55, 0.75);
        padding: 10px;
        color: #fff;
        font-size: 15px;
        line-height: 22px;
        font-style: italic;
        text-align: justify;
        z-index: 1;
    }
    .slide-inner{
        width:auto;
        height:630px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
</style>
