<template>
    <div>
        <Header text="Kontakt" :image="require('@/assets/contact/contact.jpg')" />
        <Divider text="Firemné údaje a kontakt" alignment="center" />
        <div class="informations">
            <div class="informations-inner">
                <div class="informations-inner-block">
                    <b>K2flora s.r.o</b><br/>
                    Tylova 1044/19, 831 04 Bratislava<br/>
                    <a href="mailto:k2flora@k2flora.sk">k2flora@k2flora.sk</a><br /><br />

                    IČO: 53 722 647<br/>
                    DIČ: 2121 480 746<br/>
                    IČ DPH: SK 2121 480 746
                </div>
                <div class="informations-inner-block">
                    Ing. Michaela Kovárová<br/>
                    <a href="mailto:michaela@k2flora.sk">michaela@k2flora.sk</a><br />
                    <a href="tel:+421 902 434 766">+421 902 434 766</a><br />
                    <br />
                    <a href="https://www.instagram.com/invites/contact/?i=11h8z5664ai3a&utm_content=of4o1pb" target="_blank">
                        <img src="../assets/instagram.svg" alt="Instagram">
                    </a>
                    <a href="https://facebook.com/K2Flora-101280879248449/" target="_blank" style="margin-left: 10px;">
                        <img src="../assets/facebook.svg" alt="Facebook">
                    </a>
                </div>
            </div>
        </div>
        <div class="informations" style="background-color: rgb(52, 153, 55);">

            <div class="form-inner">
                <div class="loading" v-if="loading">
                    <HalfCircleSpinner :animation-duration="1000" :size="60" color="#ffffff"/>
                </div>
                <div class="email-success" v-if="emailSuccess">
                    <div>Ďakujeme za Vašu správu!<br/>
                        Kontaktujeme Vás hneď, ako to bude možné <br />- K2flora Tím</div>
                    <div>
                        <img src="../assets/contact/success.svg" />
                    </div>
                </div>
                <div class="email-failed" v-if="emailFailed">
                    <div>Správu sa nepodarilo odoslať!<br/>
                        V prípade problému nás kontaktujte na e-mailovú adresu uvedenú v hornej sekcii.</div>
                    <div>
                        <img src="../assets/contact/failed.svg" />
                    </div>
                </div>
                <form @submit.prevent="sendEmail" v-if="!emailSuccess && !emailFailed && !loading">
                    <label>S čím môžeme pomôcť?</label><br />
                    <textarea class="form-input" name="text" :class="{'error' : textErr}" v-model="email.text" ></textarea>
                    <div class="err" v-if="textErr"><b>{{ textErr }}</b></div>
                    <br /><br />
                    <div class="two-inputs">
                        <div>
                            <label>E-mail</label><br />
                            <input type="email" name="email" class="form-input" :class="{'error' : emailErr}" v-model="email.email" />
                            <div class="err" v-if="emailErr"><b>{{ emailErr }}</b></div>
                        </div>
                        <div>
                            <label>Tel. číslo</label><br />
                            <input type="tel" name="phone" class="form-input" placeholder="+421..." :class="{'error' : phoneErr}" v-model="email.phone" />
                            <div class="err" v-if="phoneErr"><b>{{ phoneErr }}</b></div>
                        </div>
                    </div>
                    <button class="submit" type="submit">Odoslať</button>
                </form>
            </div>
        </div>
        <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d665.1577676246815!2d17.158438528552956!3d48.17519129251826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476c8ee79a214495%3A0x95ac166688ecfd87!2sTylova%201044%2F19%2C%20831%2004%20Bratislava!5e0!3m2!1ssk!2ssk!4v1681321207191!5m2!1ssk!2ssk"
                width="100%"
                height="600"
                frameBorder="0"
                allowFullScreen
        />
    </div>
</template>

<script>
    import Divider from "../components/Divider";
    import Header from "../components/Header";
    import emailjs from 'emailjs-com';
    import { HalfCircleSpinner } from 'epic-spinners'
    export default {
        name: 'App',
        components: {
            Divider,
            Header,
            HalfCircleSpinner
        },
        data(){
            return {
                email:{
                    text: '',
                    phone: '',
                    email: '',
                },
                textErr: '',
                phoneErr: '',
                emailErr: '',
                reg: RegExp(
                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                ),
                regPhone: RegExp(
                    /\+421\d{9}/
                ),
                emailSuccess: false,
                emailFailed: false,
                loading: false,
            }
        },
        methods:{
            sendEmail(e){
                var errors = 0;
                if(!this.email.text){
                    this.textErr='Vyplňte pole so správou!';
                    errors++;
                }else {
                    this.textErr='';
                }
                if(!this.email.email){
                    this.emailErr='Vyplňte pole s e-mailom!';
                    errors++;
                }else{
                    this.emailErr='';
                    if(!this.reg.test(this.email.email)){
                        errors++;
                        this.emailErr = "Zlý formát e-mailu";
                    }else {
                        this.emailErr='';
                    }
                }
                if(!this.email.phone){
                    this.phoneErr = 'Vyplňte pole s telefónnym číslom!';
                    errors++;
                }else{
                    this.phoneErr = '';
                    if(!this.regPhone.test(this.email.phone)){
                        errors++;
                        this.phoneErr = "Zlý formát telefónneho čísla! Skúste formát +421908908908"
                    }else{
                        this.phoneErr = ''
                    }
                }
                if(errors == 0){
                    this.loading = true;
                    this.sendTemplateEmail(e)
                }
            },
            async sendTemplateEmail(e){
                try {
                    emailjs.sendForm('service_rg063vo', 'template_t7g8wse', e.target, 'user_tc3CBjPuSyTwP4i76iVUi')
                        .then((result) => {
                            this.loading = false;
                            this.emailSuccess = true;
                            console.log('SUCCESS!', result.status, result.text);
                        }, (error) => {
                            this.loading = false
                            this.emailFailed = true;
                            console.log('FAILED...', error);
                        });

                } catch(error) {
                    console.log({error})
                }
            }

        },
        created(){
            document.title = 'Kontakt | k2flora'
        }
    }
</script>

<style scoped>
    .informations{
        width:100%;
        background-color:#F8F9FD;
        font-size: 20px;
    }
    .informations-inner{
        width: 940px;
        padding: 60px 20px;
        margin: 0px auto;
        display:flex;
        box-sizing: border-box;
    }
    .informations-inner-block{
        width:50%;
    }
    .informations-inner-block a{
        color: #000000;
        font-weight: 600;
    }
    .informations-inner-block a:focus{
        color: #000000;
    }
    .informations-inner-block a:hover{
        text-decoration: none;
    }
    .form-inner{
        padding: 60px 20px;
        margin: 0px auto;
        width: 940px;
        box-sizing: border-box;
    }
    .form-input {
        width:100%;
        box-sizing: border-box;
        resize: none;
        font-size: 16px;
        border: 1px solid #ccc;
        padding: 9px;
    }
    .two-inputs{
        display: flex;
        width: auto;
        justify-content: space-around;
    }
    .two-inputs div{
        width:100%;
    }
    .two-inputs div:nth-child(2){
        margin-left: 10px;
    }
    .two-inputs div:nth-child(1){
        margin-right: 10px;
    }
    .err{
        color: #fff;
        font-size: 15px;
    }
    .error{
        outline: #9a000a solid 2px;
    }
    .submit{
        background-color: #000;
        padding:13px 50px;
        color: #fff;
        border: none;
        font-weight: 600;
        display:block;
        margin-left: auto;
        margin-right: 0px;
        margin-top: 20px;
    }
    .submit:hover{
        cursor: pointer;
    }
    textarea{
        width:100%;
        height: 140px;
    }
    label{
        font-size: 14px;
        line-height: 22px;
        color:white;
        font-weight: 600;
        margin-bottom: 5px;
    }
    .email-success, .email-failed{
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .email-failed img, .email-success img{
        width: 30%;
        min-width: 200px;
        margin-top: 30px;
    }
    .loading{
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40px auto 40px auto;
    }
    @media screen and (max-width: 940px) {
        .informations-inner{
            width: auto;
            flex-flow: column;
            text-align: center;
        }
        .informations-inner-block{
            width: auto;
        }
        .form-inner{
            width: auto;
        }
    }
</style>
