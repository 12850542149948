<template>
    <div>
        <Header text="Blog" :image="require('@/assets/gallery/machove-steny/01.jpg')" />
        <div class="wrapper">
            <div class="heading">
                <div class="title">
                    <h2>
                        Fakty a mýty o vegetačných strechách
                    </h2>
                </div>
                <div class="date">
                    13.06.2021
                </div>
            </div>
            <div class="content">
                <p>
                    <i>
                        Nastupujúci trend zlepšovania kvality života a bývania v mestách praje “zelenej architektúre”.
                        S rozrastajúcou sa realizáciou, napríklad aj vegetačných striech, sa tak stáva centrom záujmu
                        pre širšiu verejnosť.
                    </i>
                </p>
            </div>
            <div class="content">
                <p>
                    <b>
                        Fakt: Vegetačné strechy ochladzujú vonkajšie aj vnútorné prostredie budov v lete!
                    </b>
                </p>
                <p>
                    V letných dňoch, kedy sa teplota dostane na 30 °C, je teplota bežne ploche strechy na povrchu okolo
                    70 °C. Teplota v rovnakom čase na vegetačnej streche sa pohybuje okolo 27 °C-30 °C.
                </p>
                <p>
                    Ako je to možné? Vegetačné strechy sú “zásobárne vody”. Vďaka tomu, v lete odparujúca sa voda
                    chladí okolitý priestor. Odparením jedného litra vody pritom budova ušetrí približne 0,7 kWh
                    energie potrebnej na prevádzku chladenia. Ak si to premietneme na plochu a vyčíslime úsporu,
                    tak 1 mm zrážok zachytených v retenčnom systéme vegetačnej strechy, na ploche 100m2 dokáže svojím
                    odparením ušetriť 70 kWh energie potrebnej na chladenie vnútorných priestorov budovy v letných dňoch!
                </p>
                <p>
                    <b>
                        Mýtus: Vegetačná strecha je bezúdržbová?!
                    </b>
                </p>
                <p>
                    Či už extenzívna, polo intenzívna alebo intenzívna, o všetky sa treba primerane starať. Údržba
                    extenzívnej strechy je nenáročná. Spočíva v občasnom odburinení, dosadení vypadnutých rastlín,
                    či v letných mesiacoch pri dlhšie trvajúcom suchu, v miernej závlahe. S výškou substrátu rastie aj
                    náročnosť starostlivosti. Avšak ani pri intenzívnych strechách sa nedá hovoriť o údržbe, ktorú by
                    človek sám nezvládol.
                </p>
                <p>
                    <b>
                        Fakt: Vegetačné strechy a vegetačné fasády produkujú kyslík.
                    </b>
                </p>
                <p>
                    Podľa typu strechy a intenzity výsadby dokáže 10 m2 extenzívnej zelenej strechy vyprodukovať za 1
                    deň kyslík pre 1 dospelého človeka! Pri vegetačných fasádach dokáže rovnaká plocha vegetácie
                    vyprodukovať kyslík až pre 30-tich dospelých ľudí za deň! Priamo úmerne k produkcii kyslíka patrí
                    aj spotreba oxidu uhličitého.
                </p>
                <div class="image" :style="{ backgroundImage : 'url('+image+')' }">
                </div>
                <p>
                    <b>
                        Mýtus: Predstavujú veľkú investíciu a nemôže si ich dovoliť každý.
                    </b>
                </p>
                <p>
                    O niečo vyššie vstupné náklady na realizáciu sú kompenzované s celým radom pozitívnych aspektov
                    vegetačných striech opísaných vyššie. Vstupné investície dnes už nie sú merateľným nástrojom pre
                    kvantitatívne zaradenia budovy do oblasti drahá/lacná. Merateľnými faktormi sú prevádzkové náklady,
                    tvorba emisií, čas za ktorý je potrebná opätovná obnova stavby či jej častí no a s tým spojená
                    uhlíková stopa.
                </p>
                <p>
                    <b>
                        Fakt: Znižujú prevádzkové náklady, šetria vaše peniaze.
                    </b>
                </p>
                <p>
                    Vegetačná strecha plní funkciu izolácie. V zime prispieva k udržaniu tepla v budove a v lete naopak,
                    chráni vnútorný priestor pred prehrievaním. V kombinácii s odborne vypracovanými detailmi zvyšku
                    stavby tak dokáže významne eliminovať potrebu budovy na vykurovanie a chladenie.
                </p>
                <p>
                    <b>
                        Fakt: Sú prírodným absorbérom hluku a prachu.
                    </b>
                </p>
                <p>
                    Vegetačné strechy sú priateľom alergikov. Sú to prírodné práčky vzduchu. Vďaka odparovaniu vody
                    zvyšujú vlhkosť vzduchu, čo pomáha k lepšiemu pocitu z dýchania v ich blízkosti. Absorbujú prach z
                    okolitého prostredia. Rastliny pohlcujú CO2 a produkujú kyslík. Vegetácia na streche tvorí prírodnú
                    protihlukovú stenu. Tlmí hluk z prostredia a zlepšuje kvalitu bývania.
                </p>

            </div>
        </div>
        <div class="medium">
            <a href="https://medium.com/@k2projekt.sk/fakty-a-m%C3%BDty-o-vegeta%C4%8Dn%C3%BDch-strech%C3%A1ch-275cec47dd14" target="_blank">
                <img src="../../assets/blog/medium-logo.svg" alt="medium-logo" />
            </a>
        </div>
    </div>
</template>
<script>
    import Header from "../../components/Header"
    export default {
        name: 'Post1',
        components: {
            Header,
        },
        data() {
            return {
                image: require('@/assets/blog/post-1/01.jpg')
            }
        },
        created(){
            document.title = 'Fakty a mýty o vegetačných strechách - Blog | k2flora'
        }
    }
</script>
<style scoped>
    .wrapper{
        width: 940px;
        margin: 0 auto;
    }
    .heading{
        background-color: #000000;
        margin-top: 25px;
        padding: 25px 20px;
        color: #fff;
    }
    .content{
        padding: 25px 20px;
        margin: 0px;
        text-align: justify;
        font-size: 18px;
        line-height: 28px;
    }
    .content:nth-child(2){
        border-left: 3px solid #349937;
        margin-top:25px;
    }
    .image{
        width: 100%;
        height: 70vh;
        background-size: cover;
        background-position: center center;
    }
    .medium{
        display: block;
        margin: 0 auto;
        width: 100px;
    }
    @media screen and (max-width: 980px) {
        .wrapper{
            width: auto;
        }
    }

</style>
