<template>
    <div class="divider-header" :style="{textAlign: alignment}">
        <h2>{{text}}</h2>
    </div>
</template>
<script>
    export default {
        name: 'Divider',
        props:{
            text: String,
            alignment: String
        }
    }
</script>
<style scoped>
    .divider-header{
        width: auto;
        color: #fff;
        padding: 20px 30px;
        font-weight: 600;
        background-color: #000;
    }
</style>
