<template>
    <div class="footer">
        <p>
            Vytvorené a spravované spoločnosťou
            <a href="https://www.vector011.com" target="_blank">vector011</a>.
            Informácie <router-link to="/gdpr"><a>GDPR</a></router-link>
        </p>
    </div>
</template>
<script>
    export default {
        name: 'Footer'
    }
</script>
<style scoped>
    .footer{
        width:auto;
        background-color:#F8F9FD;
        padding:20px;
        text-align: center;
    }
    .footer p{
        font-size: 14px;
        line-height: 22px;
        color:#000000;
    }
    .footer a{
        color:#000000;
        font-weight: bold;
    }
    .footer a:focus{
        color:#000000;
    }
    .footer a:hover{
        text-decoration: none;
    }
</style>
