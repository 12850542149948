<template>
    <div class="wrapper">
        <Header text="Galéria" :image="require('@/assets/gallery/gallery.jpg')" />
        <div v-if="galleries.length > 0">
            <div v-for="gallery in galleries" :key="gallery.id">
                <Divider :text="gallery.name" alignment="left" />
                <Gallery :images="gallery.images" :text="gallery.description" />
            </div>
        </div>
        <div class="loading" v-else>
            <img src="../assets/loading.svg">
        </div>
    </div>
</template>
<script>
    import Header from "../components/Header"
    import Divider from "../components/Divider"
    import Gallery from "../components/Gallery"
    import axios from "axios";

    export default {
        name: 'Galeria',
        components: {
            Header,
            Divider,
            Gallery
        },
        data() {
            return {
                galleries: [],
            }
        },
        methods: {
            getGalleries() {
                axios.get('https://k2flora-cms.herokuapp.com/api/galleries?populate=*&sort=id')
                .then(response => {
                    let galleries = [];
                    response.data.data.map((item) => {
                        let images = item.attributes.photo.data.map((photo) => {                       
                            return photo.attributes.url;
                        })
                        let gallery = {
                            'name': item.attributes.name,
                            'description': item.attributes.description,
                            'images': images
                        };
                        galleries.push(gallery);
                    });
                    this.galleries = galleries;
                });
            }
        },
        created(){
            this.getGalleries();
            document.title = 'Galéria | k2flora'
        }
    }
</script>
<style scoped>
.loading {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
    .wrapper{
        width: auto;
    }
    .wrapper a{
        color: #fff;
    }
    .wrapper a:hover{
        text-decoration: none;
    }
    .wrapper a:focus{
        color: #fff;
    }
    .slide-inner{
        width:auto;
        height:100vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    .slide-inner h1{
        font-size: 64px;
        line-height: 84px;
        font-weight: 600;
        color: #fff;
    }
    .content{
        position: absolute;
        left: 0px;
        top: 20%;
        padding: 50px;
        height:100vh;
    }
    .description{
        max-width: 600px;
        background-color: rgba(52, 153, 55, 0.75);
        padding: 10px;
    }
    .description p{
        color: #fff;
        font-size: 15px;
    }
    @media screen and (max-width: 720px) {
        .content{
            top:10%;
        }
        .description{
            max-width: none;
            width: auto;
        }
    }

</style>
