<template>
    <div class="wrapper">
        <Header text="Hodnoty" :image="require('@/assets/hodnoty/hodnoty.jpg')" />
        <div class="content">
            <div class="item">
                <div class="text order-first">
                    <h2>“Len spoločne vieme robiť veľké zmeny!”</h2>
                    <p>
                        V súčasnosti, si možno viac ako kedykoľvek predtým, uvedomujeme vlastnú spätosť s okolím, v
                        ktorom sa nachádzame. Našu pozornosť sústredíme viac na okolie a vzájomné interakcie s ním.
                        Zisťujeme, že spokojnosť nášho bytia závisí stále menej od vzájomných súbojov s okolím, ľuďmi,
                        prírodou, a stále viac od spolupráce a partnerstiev.
                    </p>
                </div>
                <div class="illustration order-second">
                    <img src="../assets/hodnoty/spolocne.svg" alt="spolocne-vieme-robit.svg" />
                </div>
            </div>
            <div class="item">
                <div class="text order-second">
                    <h2>“Pretože nám na životnom prostredí záleží!”</h2>
                    <p>
                        V našej spoločnosti sme sa rozhodli urobiť krok vpred. Prinášame priestor otvorený pre
                        spolupráce, kde tvorivosť, kreativita a dobrá myšlienka nájde svoju úspešnú cestu až k
                        realizácii.
                    </p>
                </div>
                <div class="illustration order-first">
                    <img src="../assets/hodnoty/zalezi.svg" alt="zalezi-na-prostredi.svg"/>
                </div>
            </div>
            <div class="item">
                <div class="text order-first">
                    <h2>“Prinášame viac zelene do miest.”</h2>
                    <p>
                        Naším primárnym cieľom je:
                    </p>
                    <ul>
                        <li>stabilizácia mikroklímy,</li>
                        <li>prinavrátenie zelene do miest a</li>
                        <li>v merateľnom faktore zlepšenie životného prostredia.</li>
                    </ul>
                </div>
                <div class="illustration order-second">
                    <img src="../assets/hodnoty/viac-zelene.svg" alt="viac-zelene.svg"/>
                </div>
            </div>
            <div class="item">
                <div class="text order-second">
                    <h2>“Sme zacielení na dizajn a funkčnosť. “</h2>
                    <ul>
                        <li>Posúvame hranice dizajnu a prinášame živé stavby.</li>
                        <li>Tvoríme detailne prepracované diela, ktoré sú funkčné.</li>
                        <li>Naše produkty sú určené pre všetky typy stavieb.</li>
                        <li>Citlivo a cielene dokážeme priviesť zeleň pred vaše okná, či priamo do vašich interiérov.</li>
                    </ul>
                </div>
                <div class="illustration order-first">
                    <img src="../assets/hodnoty/dizajn.svg" alt="dizajn.svg"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Header from "../components/Header"
    export default {
        name: 'Hodnoty',
        components: {
            Header,
        },
        data() {
            return {
            }
        },
        created(){
            document.title = 'Hodnoty | k2flora'
        }
    }
</script>
<style scoped>
    .wrapper{
        width: auto;
    }
    .content{
        width: 940px;
        margin: 20px auto 20px auto;
        padding: 20px;
    }
    .item{
        display: flex;
        align-items: center;
        margin-top: 50px;
    }
    .text{
        flex: 1;
        text-align: justify;
    }
    .text h2{
        color: #349937;
    }
    .illustration{
        flex: 1;
    }
    .illustration img{
        width: 100%;
    }
    .order-first{
        order: 1;
        padding-right: 20px;
    }
    .order-second{
        order: 2;
        padding-left: 20px;
    }
    @media screen and (max-width: 980px) {
        .content{
            width: 720px;
        }

    }
    @media screen and (max-width: 760px) {
        .content{
            width: auto;
        }
        .item{
            flex-direction: column;
            justify-content: center;
            align-items: inherit;
        }
        .illustration{
            width: 100%;
            margin-top: 25px;
        }
        .order-first {
            order: inherit;
            padding-right: 0;
        }

        .order-second {
            order: inherit;
            padding-left: 0;
        }
    }

</style>
