<template>
    <div>
        <Header text="Blog" :image="require('@/assets/gallery/machove-steny/01.jpg')" />
        <div class="wrapper">
            <div class="heading">
                <div class="title">
                    <h2>
                        Vegetačné interiérové steny
                    </h2>
                </div>
                <div class="date">
                    19.06.2021
                </div>
            </div>
            <div class="content">
                <p>
                    <i>
                        Odbúravajú stres, zlepšujú vnútornú klímu interiéru, pohlcujú hluk a prach.
                    </i>
                </p>
            </div>
            <div class="content">
                <p>
                    Interiérové vegetačné steny dnes nájdete na recepciách hotelov, v obchodoch, v reštauráciách, ale
                    aj v obývačkách domov a bytov. Miesto si našli všade tam, kde má zeleň zelenú. Sú pastvou pre oči a
                    pôsobia ako balzam na dušu. Prinášajú do interiérov harmóniu, odbúravajú stres a zlepšujú vnútornú
                    mikroklímu.
                </p>
                <div class="image" :style="{ backgroundImage : 'url('+image1+')' }">
                </div>
                <p>
                    Zeleň v interiéri má svoje miesto od pradávna. Či už formou výsadby v kvetináčoch, alebo aj
                    netradične, vertikálne, na stenách.
                </p>
                <p>
                    Práve vertikálne spôsoby pestovania interiérových rastlín si rýchlo našli svoje miesto v
                    architektúre a dizajne interiérov. Pôsobia elegantne, dokážu byť harmonizujúcim a dynamicky
                    dominantným prvkom, vďaka čomu sa tešia obľube na všetkých miestach, kde je potrebné upútať a
                    reprezentovať.
                </p>
                <p>
                    Interiérové vegetačné steny rozdeľujeme podľa spôsobu pestovania na:
                </p>
                <ul>
                    <li><p>pestovanie v substráte</p></li>
                    <li><p>hydroponické pestovanie.</p></li>
                </ul>
                <p>
                    <b>
                        Pestovanie v substráte
                    </b>
                </p>
                <p>
                    dáva rastlinkám priestor na pevné a stabilné zakorenenie. Vhodné je pre izbové rastliny určené na
                    pestovanie v substrátoch. Tieto vegetačné steny majú po vysadení značnú váhu. Vyžadujú si pevný
                    podklad a kotvenie do oporných stien. Ponúkajú však rôznorodosť a širokú pestrosť. Vďaka oddeleným
                    boxom umožňujú pestovanie rôznych druhov rastlín vedľa seba.
                </p>
                <p>
                    Svetlo, voda a pôda sú tri primárne faktory, ktoré ovplyvňujú rast a života schopnosť rastlín v
                    týchto systémoch. Ich správny návrh zabezpečuje stabilitu a funkčnosť na dlhé obdobie.
                </p>
                <p>
                    V interiéroch je potrebné pamätať na to, že vegetačné steny potrebujú dostatok svetla. Správne
                    navrhnutým osvetlením, tak docielime bohatú fotosyntézu a prosperitu rastlinám. Zemný substrát
                    musí dýchať. Vzdušnosť koreňového systému napomáha rastu a vitalite. Či už manuálna alebo
                    automatická. Správne načasovaná závlaha, ako aj množstvo vody sú pre život vegetačných stien
                    rovnako dôležité ako svetlo a pôda.
                </p>
                <div class="image" :style="{ backgroundImage : 'url('+image2+')' }">
                </div>
                <p>
                    <b>
                        Hydroponické pestovanie
                    </b>
                </p>
                <p>
                    je dnes moderný a veľmi populárny systém pestovania rastlín. Prináša so sebou odľahčenie
                    vegetačných interiérových stien, a tak umožňuje ich realizáciu aj na miestach
                    „s krehkejšou statikou“. Vďaka odľahčeniu je možné ich realizovať v každom interiéri a do
                    veľkých výšok. Pre hydroponickú výsadbu sú vhodné vybrané druhy rastlín. Ich rôznorodosť a
                    farebnosť umožňuje vytvárať zaujímavé a estetické zoskupenia.
                </p>
                <p>
                    je dnes moderný a veľmi populárny systém pestovania rastlín. Prináša so sebou odľahčenie
                    vegetačných interiérových stien, a tak umožňuje ich realizáciu aj na miestach
                    „s krehkejšou statikou“. Vďaka odľahčeniu je možné ich realizovať v každom interiéri a do
                    veľkých výšok. Pre hydroponickú výsadbu sú vhodné vybrané druhy rastlín. Ich rôznorodosť a
                    farebnosť umožňuje vytvárať zaujímavé a estetické zoskupenia.
                </p>
                <p>
                    Svetlo, voda a hnojivá sú pre hydroponické vegetačné steny kľúčové. Podobne, ako pri pestovaní v
                    substráte, je pre rastliny mimoriadne dôležité správne svetlo a správne načasovanie závlahy, ako aj
                    množstvo vody, ktoré procesom závlahy prejde. V neposlednom rade pre správny rast a vitalitu je
                    potrebné rastlinky hnojiť. Overeným riešením sú rozpustné hnojivá do vody. Práve chýbajúci substrát,
                    ktorý v sebe skrýva rad užitočných látok pre rast a vývoj rastlín, je pri hydroponickom pestovaní
                    nahradený správnymi hnojivami.
                </p>
            </div>
            <div class="content">
                <p>
                    <i>
                        <b>K2flora</b> má v ponuke hneď dva overené a funkčné systémy. Oba vhodné ako pre substrátové,
                        tak aj pre hydroponické pestovanie rastlín v interiéri. <b>Flora Panel a PlantBox</b>
                        sú systémy adaptabilné
                        aj do exteriéru. Umožňujú vyhotoviť výsadbu takmer akéhokoľvek typu a sú „hobby friendly“ pre
                        bežné drobné pestovanie doma na balkónoch a terasách. Vďaka vyvinutému systému však s nimi
                        dokážeme realizovať rozsiahle a vysoké interiérové steny.
                    </i>
                </p>
            </div>
        </div>
        <div class="medium">
            <a href="https://medium.com/@k2projekt.sk/vegeta%C4%8Dn%C3%A9-interi%C3%A9rov%C3%A9-steny-43a0dd091d0e" target="_blank">
                <img src="../../assets/blog/medium-logo.svg" alt="medium-logo" />
            </a>
        </div>
    </div>
</template>
<script>
    import Header from "../../components/Header"
    export default {
        name: 'Post1',
        components: {
            Header,
        },
        data() {
            return {
                image1: require('@/assets/blog/post-2/01.jpg'),
                image2: require('@/assets/blog/post-2/02.jpg'),
            }
        },
        created(){
            document.title = 'Vegetačné interiérové steny - Blog | k2flora'
        }
    }
</script>
<style scoped>
    .wrapper{
        width: 940px;
        margin: 0 auto;
    }
    .heading{
        background-color: #000000;
        margin-top: 25px;
        padding: 25px 20px;
        color: #fff;
    }
    .content{
        padding: 25px 20px;
        margin: 0px;
        text-align: justify;
        font-size: 18px;
        line-height: 28px;
    }
    .content:nth-child(2), .content:nth-child(4){
        border-left: 3px solid #349937;
        margin-top:25px;
    }
    .image{
        width: 100%;
        height: 70vh;
        background-size: cover;
        background-position: center center;
    }
    .medium{
        display: block;
        margin: 0 auto;
        width: 100px;
    }
    @media screen and (max-width: 980px) {
        .wrapper{
            width: auto;
        }
    }

</style>
