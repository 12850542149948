<template>
    <div class="wrapper">
        <Header text="Partneri" :image="require('@/assets/partners/partners.jpg')" />
        <div v-for="partner in partners" :key="partner.id">
            <Divider :text="partner.attributes.name" alignment="center" />
            <div class="partners">
                <div class="partner" v-for="each in partner.attributes.partners.data" :key="each.id">
                    <a :href="each.attributes.url" target="_blank">
                        <img :src="each.attributes.image.data.attributes.url">
                    </a>
                </div>
            </div>
        </div>
        <!--
            <Divider text="Projekčný tím" alignment="center" />

        <div class="partners">
            <div class="partner">
                <a href="https://k2projekt.sk" target="_blank">
                    <img src="../assets/partners/k2projekt.svg">
                </a>
            </div>
        </div>

        <Divider text="Partneri pre realizáciu" alignment="center" />

        <div class="partners">
            <div class="partner">
                <a href="https://www.liko-s.sk/sk" target="_blank">
                    <img src="../assets/partners/likos.png">
                </a>
            </div>
            <div class="partner">
                <a href="https://floraurbanica.com" target="_blank">
                    <img src="../assets/partners/floraurbanica.png">
                </a>
            </div>
            <div class="partner">
                <a href="https://www.zivestavby.sk" target="_blank">
                    <img src="../assets/partners/zive-stavby.svg">
                </a>
            </div>
            <div class="partner">
                <a href="https://www.dekonta.sk" target="_blank">
                    <img src="../assets/partners/dekonta.svg">
                </a>
            </div>
        </div>
        -->
    </div>
</template>
<script>
    import Header from "../components/Header"
    import Divider from "../components/Divider"
    import axios from "axios";
    export default {
        name: 'Partners',
        components: {
            Header,
            Divider
        },
        data() {
            return {
                partners: []
            }
        },
        methods: {
            getPartners() {
                axios.get('https://k2flora-cms.herokuapp.com/api/partner-types?populate=*')
                .then(response => {
                    this.partners = response.data.data;
                });
            }
        },
        created(){
            this.getPartners();
            document.title = 'Partneri | k2flora'
        }
    }
</script>
<style scoped>
    .wrapper{
        width: auto;
    }
    .partners{
        width: auto;
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .partner{
        width: auto;
        flex: 1;
        padding: 50px 20px;
        flex-basis: 40%;
    }
    .partner img{
        max-width: 300px;
        width: 300px;
        display: block;
        margin: 0 auto;
    }
    .wrapper a{
        color: #fff;
    }
    .wrapper a:hover{
        text-decoration: none;
    }
    .wrapper a:focus{
        color: #fff;
    }
    .slide-inner{
        width:auto;
        height:100vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    .slide-inner h1{
        font-size: 64px;
        line-height: 84px;
        font-weight: 600;
        color: #fff;
    }
    .content{
        position: absolute;
        left: 0px;
        top: 20%;
        padding: 50px;
        height:100vh;
    }
    .description{
        max-width: 600px;
        background-color: rgba(52, 153, 55, 0.75);
        padding: 10px;
    }
    .description p{
        color: #fff;
        font-size: 15px;
    }
    @media screen and (max-width: 720px) {
        .content{
            top:10%;
        }
        .description{
            max-width: none;
            width: auto;
        }
    }

</style>
