<template>
    <div>
      <div v-if="product.id">
        <Header :text="product.attributes.name" :image="product.attributes.image.data.attributes.url" />
        <div class="wrapper">
            <div class="description">
              <div class="dynamic" v-html="getMarkdownToHtml(product.attributes.description)"></div>
            </div>
        </div>
        <div v-if="product.attributes.products.data.length>0">
          <Divider text="Produkty" alignment="left" />
          <div style="width: auto; position: relative; background-color: #000; color: #fff;">
              <div v-for="item in product.attributes.products.data" :key="item.id">
                <div
                  class="accordion" @click="setActive(item.id)" 
                  :style="{ backgroundImage: 'url('+item.attributes.image.data.attributes.url+')'}"
                  >
                    <div class="overlay"></div>
                    <div class="accordion-header">
                        <div>
                            <h2>{{ item.attributes.name }}</h2>
                        </div>
                        <div class="icon" v-bind:class="compareActiveArrow(item.id)">
                            <img src="../assets/arrow.svg">
                        </div>
                    </div>
                    <div class="divider"></div>
                </div>
                <div class="accordion-content" v-bind:class="compareActive(item.id)">
                    <div class="dynamic" v-html="getMarkdownToHtml(item.attributes.description)"></div>
                </div>
              </div>
          </div>
        </div>
        
        <div v-if="gallery.length>0" >
          <Divider text="Galéria" alignment="left" />
          <Gallery :images="gallery" />
        </div>
      </div>
      <div class="loading" v-else>
        <img src="../assets/loading.svg">
      </div>
    </div>
</template>
<script>
    import Divider from '../components/Divider';
    import Header from '../components/Header';
    import Gallery from '../components/Gallery';
    import { marked } from 'marked';
    import axios from 'axios';
    export default {
        name: 'Product',
        components: {
            Gallery,
            Divider,
            Header
        },
        data(){
            return {
                activeAccordion: 0,
                primaryOptions: {
                    type: 'slide',
                    width: '100%',
                    lazyLoad: 'nearby',
                    preloadPages: 12
                },
                product: {},
                gallery: [],
            }
        },
        methods:{
            setActive:function(id){
              if(id == this.activeAccordion){
                  this.activeAccordion = 0;
              }else{
                  this.activeAccordion = id;
              }
            },
            compareActive: function (id) {
                if (id === this.activeAccordion) return 'show-accordion'
                else return 'hide-accordion'
            },
            compareActiveArrow: function (id){
                if (id === this.activeAccordion) return 'transform-arrow'
                else return ''
            },
            getMarkdownToHtml(md) {
              return marked.parse(md);
            },
            getProducts() {
                axios.get('https://k2flora-cms.herokuapp.com/api/categories')
                .then(response => {
                    this.product = response.data.data.filter((product) => product.id == this.$route.params.id)[0];    
                    if(this.product.attributes.gallery.data.length > 0 || this.product.attributes.gallery.data == null) {
                      this.gallery = this.product.attributes.gallery.data.map((photo) => {                                              
                        return photo.attributes.url;
                      });
                    } else {
                      this.gallery = [];
                    }                    
                });
            },
            getResolutionImg(sizes) {
                if(sizes.large) {
                    return sizes.large.url;
                }
                return sizes.medium.url;
            }
        },
        created(){
            this.getProducts();
            document.title = 'Produkt | k2flora'
        }
    }
</script>
<style scoped>
.loading {
        display: flex;
        height: 100vh;
        justify-content: center;
        align-items: center;
    }

.dynamic >>> ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.dynamic >>> li {
  display: block;
  background-image: url(../assets/check.svg);
  background-repeat: no-repeat; 
  background-position: left center;
  padding-left: 44px;
  margin-top: 20px;
}
div >>> a, a:visited {
  color: #349937;
  font-weight: 600;
}
div >>> a:hover {
  text-decoration: none;
}
    .wrapper{
        width:auto;
        padding: 60px 20px;
        background-color: #F8F9FD;
    }
    .description, .accordion-content{
        width: 940px;
        margin: 0 auto;
        font-size: 16px;
        line-height: 22px;
    }
    .accordion-content{
        max-height: 0px;
        overflow-y: hidden;
        opacity: 0;
        transition: all 0.4s ease-out;
    }
    .show-accordion{
        height: auto;
        padding: 20px;
        max-height: 3000px;
        opacity: 1;
    }
    .transform-arrow{
        transform: rotate(180deg) translateX(10px) translateY(5px);
    }
    .item{
        margin-top: 20px;
        display:flex;
        text-align: justify;
    }
    .item-text{
        margin-left: 30px;
    }
    .item-text a, a:visited{
        color: #349937;
        font-weight: 600;
    }
    .item-text a:hover{
        text-decoration: none;
    }

    .accordion{
        width: auto;
        background-size: cover;
        background-position: center center;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
        transition: all 0.4s ease-out;
    }
    .accordion:hover{
        cursor: pointer;
    }
    .overlay{
        position: absolute;
        background-color: rgba(0, 0, 0, 0.4);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .accordion-header{
        width: auto;
        padding: 35px 30px 35px 30px;
        font-weight: 600;
        color: #fff;
        display: flex;
        align-items: center;
        z-index: 1;
    }
    .divider{
        width: 100%;
        height:20px;
        background-color: #000000;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .icon{
        margin-top:5px;
        margin-left: 20px;
        width: 30px;
        transition: all 0.4s ease-out;
    }
    .icon img{
        width: 20px;
    }
    .slide-inner{
        width:auto;
        height:630px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    .gallery{
        width: auto;
    }

    @media screen and (max-width: 980px) {
        .description, .accordion-content{
            width: auto;
        }
    }
    @media screen and (max-width: 720px) {
        .slide-inner{
            height: 400px;
        }
        .item{
            text-align: left;
        }
    }
</style>
