<template>
    <div class="wrapper">
        <Header text="Blog" :image="require('@/assets/gallery/machove-steny/01.jpg')" />
        <div  v-if="loading==false">
            <Post
            class="posts"
                v-for="post in postsMedium"
                :key="post.guid"
                :heading="post.title"
                :text="sliceDescription(post.description)"
                :date="post.pubDate | moment('DD.MM.YYYY HH:mm:ss')"
                :url="'post/'+getArticleId(post.guid)"
            />
        </div>
    </div>
</template>
<script>
    import Header from "../components/Header"
    import Post from "../components/Post"
    import axios from "axios";
    export default {
        name: 'Blog',
        components: {
            Header,
            Post,
        },
        data() {
            return {
                postsMedium: [],
                loading: false
            }
        },
        methods: {
            getArticles() {
                this.loading = true;
                axios.get('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@k2flora.sk')
                .then(response => {

                    this.postsMedium = response.data.items;
                    this.loading = false;
                })
            },
            sliceDescription(description) {
                const str = description.toLowerCase();  
                let result = [];
                let idx = str.indexOf("\n")
                
                while (idx !== -1) {
                    result.push(idx);
                    idx = str.indexOf("\n", idx+1);
                }
                return description.substring(0,result[1]+1);
            },
            getArticleId(id) {
                return id.substring(id.lastIndexOf('/')+1, id.length)
            }
        },
        created(){
            document.title = 'Blog | k2flora'
        },
        mounted() {
            this.getArticles();
        }
    }
</script>
<style scoped>
    .wrapper{
        width: auto;
    }
    .wrapper a{
        color: #fff;
    }
    .wrapper a:hover{
        text-decoration: none;
    }
    .wrapper a:focus{
        color: #fff;
    }
    .posts:nth-child(2n+1) {
        background: #eee;
    }
    .slide-inner{
        width:auto;
        height:100vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    .slide-inner h1{
        font-size: 64px;
        line-height: 84px;
        font-weight: 600;
        color: #fff;
    }
    .content{
        position: absolute;
        left: 0px;
        top: 20%;
        padding: 50px;
        height:100vh;
    }
    .description{
        max-width: 600px;
        background-color: rgba(52, 153, 55, 0.75);
        padding: 10px;
    }
    .description p{
        color: #fff;
        font-size: 15px;
    }
    @media screen and (max-width: 720px) {
        .content{
            top:10%;
        }
        .description{
            max-width: none;
            width: auto;
        }
    }

</style>
