<template>
    <div>
        <Header text="GDPR" :image="require('@/assets/gdpr/gdpr.jpg')" />
        <div class="wrapper">
            <h2>
                Informačná povinnosť spoločnosti vypracovaná v zmysle zákona o ochrane osobných údajov
            </h2>
            <p>
                <u>Identifikačné údaje prevádzkovateľa:</u>
            </p>
            <p>Spoločnosť K2flora s. r. o., IČO: 53 722 647, Osvetová 24, 821 05 Bratislava (ďalej len „spoločnosť”) vystupuje pri spracúvaní osobných údajov svojich zamestnancov, klientov, zákazníkov alebo obchodných partnerov (ďalej len „dotknutá osoba“) ako prevádzkovateľ informačných systémov (ďalej len „IS“). </p>
            <p>
                <u>Právny základ spracúvania osobných údajov dotknutých osôb:</u>
            </p>
            <p>
                Pri spracúvaní osobných údajov spoločnosť postupuje v súlade so zákonom č. 18/2018 Z. z. o ochrane osobných údajov a o zmene a doplnení niektorých predpisov (ďalej len „zákon o ochrane osobných údajov“). Právnym základom spracúvania osobných údajov je zákon o ochrane osobných údajov, osobitné právny predpisy a súhlas so spracúvaním osobných údajov, a to v závislosti od účelu spracúvania osobných údajov.
            </p>
            <p>
                V prípade, ak účel spracúvania osobných údajov, okruh dotknutých osôb a zoznam osobných údajov ustanovuje priamo vykonateľný akt Európskej únie, medzinárodná zmluva, ktorou je Slovenská republika viazaná, zákon o ochrane osobných údajov alebo osobitný zákon je spoločnosť v zmysle zákona o ochrane osobných údajov oprávnená spracúvať osobné údaje bez súhlasu dotknutej osoby.
            </p>
            <p>
                Spoločnosť spracúva osobné údaje bez súhlasu dotknutej osoby, ak účel spracúvania osobných údajov, okruh dotknutých osôb a zoznam osobných údajov alebo ich rozsah ustanovuje priamo vykonateľný právne záväzný akt Európskej únie, medzinárodná zmluva, ktorou je Slovenská republika viazaná, alebo tento zákon. Ak zoznam alebo rozsah osobných údajov nie je ustanovený, spoločnosť môže spracúvať osobné údaje len v rozsahu a spôsobom, ktorý je nevyhnutný na dosiahnutie ustanoveného účelu spracúvania pri dodržaní základných povinností podľa zákona o ochrane osobných údajov.
            </p>
            <p>
                Spoločnosť ďalej spracúva osobné údaje bez súhlasu dotknutej osoby, ak účel spracúvania osobných údajov, okruh dotknutých osôb a zoznam osobných údajov ustanovuje osobitný zákon a to len v rozsahu a spôsobom, ktorý ustanovuje osobitný zákon. Spracúvané osobné údaje možno z informačného systému poskytnúť, sprístupniť alebo zverejniť len vtedy, ak osobitný zákon ustanovuje účel poskytovania, sprístupňovania alebo zverejňovania, zoznam osobných údajov, ktoré možno poskytnúť, sprístupniť alebo zverejniť, ako aj tretie strany, ktorým sa osobné údaje poskytujú, prípadne okruh príjemcov, ktorým sa osobné údaje sprístupňujú, ak zákon o ochrane osobných údajov neustanovuje inak.
            </p>

            <p>
                Spoločnosť spracúva osobné údaje bez súhlasu dotknutej osoby aj vtedy, ak:
            </p>
            <ol type="a">
                <li>
                    spracúvanie osobných údajov je nevyhnutné na plnenie zmluvy, v ktorej vystupuje dotknutá osoba ako jedna zo zmluvných strán, alebo v predzmluvných vzťahoch s dotknutou osobou alebo pri rokovaní o zmene zmluvy, ktoré sa uskutočňujú na žiadosť dotknutej osoby,
                </li>
                <li>
                    spracúvanie osobných údajov je nevyhnutné na ochranu života, zdravia alebo majetku dotknutej osoby,
                </li>
                <li>
                    predmetom spracúvania sú výlučne titul, meno, priezvisko a adresa dotknutej osoby bez možnosti priradiť k nim ďalšie jej osobné údaje a ich využitie je určené výhradne pre potreby prevádzkovateľa v poštovom styku s dotknutou osobou a evidencie týchto údajov,
                </li>
                <li>
                    sa spracúvajú osobné údaje, ktoré už boli zverejnené v súlade so zákonom a prevádzkovateľ ich náležite označil ako zverejnené; ten, kto tvrdí, že spracúva zverejnené osobné údaje, na požiadanie preukáže úradu, že spracúvané osobné údaje boli už zákonne zverejnené,
                </li>
                <li>
                    spracúvanie osobných údajov je nevyhnutné na ochranu práv a právom chránených záujmov prevádzkovateľa alebo tretej strany, pričom to neplatí, ak pri takomto spracúvaní osobných údajov prevažujú základné práva a slobody dotknutej osoby, ktoré podliehajú ochrane podľa tohto zákona.
                </li>
            </ol>
            <p>
                Ak sa vzhľadom na účel spracúvania osobných údajov ustanovený v priamo vykonateľnom právne záväznom akte Európskej únie, medzinárodnej zmluve, ktorou je Slovenská republika viazaná, v zákone o ochrane osobných údajov a osobitnom zákone nedajú vopred konkrétne určiť jednotlivé osobné údaje, ktoré majú byť predmetom spracúvania, zoznam osobných údajov možno nahradiť rozsahom osobných údajov.

                Spoločnosť je povinná pri takomto spracúvaní osobných údajov postupovať podľa zákona o ochrane osobných údajov okrem tých prevádzkovateľov, ktorí spracúvajú osobné údaje na účely súdneho konania a v súvislosti s ním.

                V prípade, ak sa na spracúvanie osobných údajov neuplatňuje zákon o ochrane osobných údajov, je spoločnosť ako prevádzkovateľ oprávnená spracúvať osobné údaje len so súhlasom dotknutej osoby.

                Spoločnosť získava súhlas dotknutej osoby bez nátlaku a vynucovania, ako aj bez podmieňovania hrozbou odmietnutia zmluvného vzťahu, poskytovaných služieb alebo povinností vyplývajúcich pre prevádzkovateľa z právne záväzných aktov Európskej únie, medzinárodnej zmluvy, ktorou je Slovenská republika viazaná alebo zákona.

                V prípade odmietnutia poskytnutia osobných údajov spoločnosti na účely potrebné pre poskytovanie služieb alebo napĺňanie zákonných povinností je spoločnosť oprávnená upozorniť dotknutú osobu s možnými následkami neposkytnutia osobných údajov.

                Dotknuté osoby súhlasia s tým aby spoločnosť pri spracúvaní osobných údajov poverila takýmto spracúvaním sprostredkovateľa, ktorý spracúva osobné údaje v mene spoločnosti. Po skončení účelu spracúvania osobných údajov spoločnosť tieto zákonne získané osobné údaje dotknutých osôb zlikviduje v lehote stanovenej platnými právnymi predpismi a v súlade s interným predpisom spoločnosti.

            </p>
            <p>
                <u>Účel spracúvania osobných údajov dotknutých osôb:</u>
            </p>
            <p>
                Spoločnosť rešpektuje Vaše súkromie a poskytnuté osobné údaje považuje za dôverné.

                Spoločnosť potrebuje pre kvalitné poskytovanie svojich služieb poznať niektoré osobné údaje dotknutých osôb a potrebuje ich poskytovať ďalším príjemcom za účelom plnenia si zákonných povinností a zabezpečovanie služieb najvyššej kvality.

                Spoločnosť spracúva poskytnuté osobné údaje za viacerými účelmi.
                Jednak ide o osobné údaje uchádzačov o zamestnanie a osobné údaje svojich zamestnancov na účely personálnej a mzdovej agendy, a s tým súvisiacich zákonných povinností vyplývajúcich z osobitných právnych predpisov.

                Spoločnosť ďalej spracúva aj osobné údaje svojich klientov, zákazníkov a obchodných partnerov za účelom zabezpečovania svojej podnikateľskej činnosti s prihliadnutím na záujmy svojich klientov, zákazníkov a obchodných partnerov.

                K spracúvaniu osobných údajov za iným účelom v spoločnosti nedochádza, čo znamená, že spoločnosť zhromažďuje, uchováva a spracúva len osobné údaje dotknutých osôb, ktoré potrebuje za účelom naplnenia svojich poskytovaných služieb. Poskytnuté osobné údaje sú prísne chránené proti zneužitiu zo strany tretích neoprávnených subjektov, a to prostriedkami zadokumentovanými v prijatom bezpečnostnom projekte a bezpečnostnej smernici v zmysle zákona o ochrane osobných údajov.

                Pri spracúvaní osobných údajov dotknutých osôb spoločnosť dodržiava základné povinnosti prevádzkovateľa vyplývajúce z zákona o ochrane osobných údajov medzi ktoré patria aj nasledovné povinnosti.

                Spoločnosť poskytnuté osobné údaje využíva vždy na vopred stanovený účel spracúvania, ktorý je jasný, vymedzený jednoznačne a konkrétne, pričom je v súlade s Ústavou Slovenskej republiky, ústavnými zákonmi, zákonmi a medzinárodnými zmluvami, ktorými je Slovenská republika viazaná.

                Spoločnosť vždy vymedzuje podmienky spracúvania osobných údajov tak, aby nedošlo k obmedzeniu práv dotknutej osoby ustanovených zákonom.

                Spoločnosť získava len také osobné údaje dotknutých osôb, ktoré svojím rozsahom a obsahom zodpovedajú účelu spracúvania a sú nevyhnutné na jeho dosiahnutie.

                Spoločnosť zabezpečuje, aby sa osobné údaje dotknutých osôb spracúvali výlučne spôsobom, ktorý zodpovedá účelu, na ktorý boli vopred zhromaždené.

                Spoločnosť ako prevádzkovateľ je povinná spracúvať len správne, úplne a podľa potreby aktualizované osobné údaje vo vzťahu k účelu spracúvania. Nesprávne a neúplne osobné údaje je prevádzkovateľ povinný blokovať a bez zbytočného odkladu opraviť alebo doplniť, ak ich nemožno opraviť alebo doplniť tak, aby boli správne, spoločnosť tieto osobné údaje zreteľne označí a bez zbytočného odkladu zlikviduje.

                Spoločnosť zabezpečuje, aby osobné údaje dotknutých osôb boli spracúvané vo forme umožňujúcej identifikáciu jednotlivých dotknutých osôb počas doby nie dlhšej, ako je nevyhnutné na dosiahnutie účelu spracúvania.

                Spoločnosť predpísaným spôsobom zlikviduje tie osobné údaje, ktorých účel spracúvania sa skončil. Po skončení vymedzeného účelu je spoločnosť oprávnená spracúvať osobné údaje v nevyhnutnom rozsahu a to na výskum alebo na účely štatistiky v ich anonymizovanej podobe. Takto spracúvané osobné údaje prevádzkovateľ nemôže použiť na podporu opatrení alebo rozhodnutí prijatých proti dotknutej osobe na obmedzenie jej základných práv a slobôd.

            </p>
            <p>
                <u>
                    Sprostredkovatelia:
                </u>
            </p>
            <p>
                Spoločnosť neposkytuje Vaše osobné údaje tretím osobám v rozpore so zákonom o ochrane osobných údajov a za účelom ich zhromažďovania, v rozpore s Vašimi záujmami alebo pokynmi, a tretej osobe sú poskytované len v rámci uvedeného vyššie uvedeného účelu.

                Spoločnosť pri svojich podnikateľských aktivitách spolupracuje s viacerými sprostredkovateľmi, ktorých cieľom je poskytovanie kvalitných služieb, pričom tieto subjekty pri výkone svojej zmluvnej činnosti pre spoločnosť spracúvajú osobné údaje dotknutých osôb.

                Spoločnosť čestne vyhlasuje, že pri výbere jednotlivých sprostredkovateľov dbala na ich odbornú, technickú, organizačnú a personálnu spôsobilosť a ich schopnosť zaručiť bezpečnosť spracúvaných osobných údajov prijatými bezpečnostnými opatreniami v zmysle zákona o ochrane osobných údajov.

                Spoločnosť zároveň pri výbere vhodného sprostredkovateľa postupovala tak, aby nedošlo k ohrozeniu práv a právom chránených záujmov dotknutých osôb.

                Spoločnosť ako prevádzkovateľ uzatvorila so sprostredkovateľmi v zmysle zákona o ochrane osobných údajov písomné zmluvy o zabezpečení ochrany osobných údajov spracúvaných sprostredkovateľmi, ktorých poverila spracúvaním osobných údajov dotknutých osôb len v rozsahu, za podmienok a na účel dojednaný v zmluve a spôsobom podľa zákona o ochrane osobných údajov.

            </p>
            <p>
                <u>
                    Rozsah a zoznam spracúvaných osobných údajov:
                </u>
            </p>
            <p>
                Spoločnosť spracúva vo svojich informačných systémoch osobné údaje dotknutých osôb v rozsahu potrebnom na dosiahnutie stanoveného účelu. Ide o rozsah osobných údajov stanovených osobitnými právnymi predpismi alebo v rozsahu súhlasu dotknutej osoby na spracúvanie jej osobných údajov.

                Spoločnosť spracúva iba osobné údaje, ktoré jej boli poskytnuté dobrovoľne a v nevyhnutnej miere samotnou dotknutou osobou. Poskytnutie osobných údajov spoločnosti nad rámec osobitných zákonov je pritom dobrovoľné.

            </p>
            <p>
                <u>
                    Podmienky a spôsob spracúvania osobných údajov dotknutých osôb:
                </u>
            </p>
            <p>
                Spoločnosť spracúva vo svojich informačných systémoch osobné údaje dotknutých osôb automatizovanými i neautomatizovanými prostriedkami spracúvania.

                Spoločnosť spracúvané osobné údaje nezverejňuje, okrem prípadov, ak si to vyžaduje osobitný právny predpis alebo rozhodnutie súdu alebo iného štátneho orgánu.

                Spoločnosť nebude spracúvať Vaše osobné údaje bez Vášho výslovného súhlasu alebo iného zákonného právneho základu za iným účelom, ani vo väčšom rozsahu ako je uvedené v tejto informácii a evidenčných listoch jednotlivých informačných systémoch prevádzkovateľa.

            </p>
            <p>
                <u>
                    Práva dotknutej osoby spojené so spracúvaním jej osobných údajov:
                </u>
            </p>
            <p>
                Dotknutá osoba má právo na základe písomnej žiadosti od spoločnosti vyžadovať:
            </p>
            <ol type="a">
                <li>
                    potvrdenie, či sú alebo nie sú osobné údaje o nej spracúvané,
                </li>
                <li>
                    vo všeobecne zrozumiteľnej forme informácie o spracúvaní osobných údajov v informačnom systéme v rozsahu podľa zákona o ochrane osobných údajov; pri vydaní rozhodnutia podľa zákona o ochrane osobných údajov je dotknutá osoba oprávnená oboznámiť sa s postupom spracúvania a vyhodnocovania operácií,
                </li>
                <li>
                    vo všeobecne zrozumiteľnej forme presné informácie o zdroji, z ktorého získal jej osobné údaje na spracúvanie,
                </li>
                <li>
                    vo všeobecne zrozumiteľnej forme zoznam jej osobných údajov, ktoré sú predmetom spracúvania,
                </li>
                <li>
                    opravu alebo likvidáciu svojich nesprávnych, neúplných alebo neaktuálnych osobných údajov, ktoré sú predmetom spracúvania,
                </li>
                <li>
                    likvidáciu jej osobných údajov, ktorých účel spracúvania sa skončil; ak sú predmetom spracúvania úradné doklady obsahujúce osobné údaje, môže požiadať o ich vrátenie,
                </li>
                <li>
                    likvidáciu jej osobných údajov, ktoré sú predmetom spracúvania, ak došlo k porušeniu zákona,
                </li>
                <li>
                    blokovanie jej osobných údajov z dôvodu odvolania súhlasu pred uplynutím času jeho platnosti, ak spoločnosť spracúva osobné údaje na základe súhlasu dotknutej osoby.
                </li>
            </ol>
            <p>
                Vyššie uvedené práva dotknutej osoby podľa písmena e) a f) možno obmedziť, len ak takéto obmedzenie vyplýva z osobitného zákona alebo jeho uplatnením by bola porušená ochrana dotknutej osoby, alebo by boli porušené práva a slobody iných osôb.
            </p>
            <p>
                Podľa zákona o ochrane osobných údajov má dotknutá osoba na základe písomnej žiadosti adresovanej spoločnosti právo namietať voči:
            </p>
            <ol type="a">
                <li>
                    spracúvaniu jej osobných údajov, o ktorých predpokladá, že sú alebo budú spracúvané na účely priameho marketingu bez jej súhlasu, a žiadať ich likvidáciu,
                </li>
                <li>
                    využívaniu osobných údajov uvedených v zákona o ochrane osobných údajov na účely priameho marketingu v poštovom styku, alebo
                </li>
                <li>
                    poskytovaniu osobných údajov uvedených v zákona o ochrane osobných údajov na účely priameho marketingu.
                </li>
            </ol>
            <p>
                Podľa zákona o ochrane osobných údajov má dotknutá osoba na základe písomnej žiadosti adresovanej spoločnosti alebo osobne, ak vec neznesie odklad, právo kedykoľvek namietať voči spracúvaniu osobných údajov v prípadoch podľa zákona o ochrane osobných údajov vyslovením oprávnených dôvodov alebo predložením dôkazov o neoprávnenom zasahovaní do jej práv a právom chránených záujmov, ktoré sú alebo môžu byť v konkrétnom prípade takýmto spracúvaním osobných údajov poškodené; ak tomu nebránia zákonné dôvody a preukáže sa, že námietka dotknutej osoby je oprávnená, spoločnosť je povinná osobné údaje, ktorých spracúvanie dotknutá osoba namietala, bez zbytočného odkladu blokovať a zlikvidovať ihneď, ako to okolnosti dovolia.

                Podľa zákona o ochrane osobných údajov má dotknutá osoba na základe písomnej žiadosti adresovanej spoločnosti alebo osobne, ak vec neznesie odklad, právo kedykoľvek namietať a nepodrobiť sa rozhodnutiu spoločnosti, ktoré by malo pre ňu právne účinky alebo významný dosah, ak sa také rozhodnutie vydá výlučne na základe úkonov automatizovaného spracúvania jej osobných údajov. Dotknutá osoba má ďalej právo žiadať spoločnosť o preskúmanie vydaného rozhodnutia metódou odlišnou od automatizovanej formy spracúvania, pričom spoločnosť je povinná žiadosti dotknutej osoby vyhovieť, a to tak, že rozhodujúcu úlohu pri preskúmaní rozhodnutia bude mať oprávnená osoba; o spôsobe preskúmania a výsledku zistenia prevádzkovateľ informuje dotknutú osobu v lehote podľa zákona o ochrane osobných
                údajov. Dotknutá osoba nemá toto právo len v prípade, ak to ustanovuje osobitný zákon, v ktorom sú upravené opatrenia na zabezpečenie oprávnených záujmov dotknutej osoby, alebo ak v rámci predzmluvných vzťahov alebo počas existencie zmluvných vzťahov prevádzkovateľ vydal rozhodnutie, ktorým vyhovel požiadavke dotknutej osoby, alebo ak prevádzkovateľ na základe zmluvy prijal iné primerané opatrenia na zabezpečenie oprávnených záujmov dotknutej osoby.
            </p>
            <p>
                Ak dotknutá osoba uplatní svoje právo:
            </p>
            <ol type="a">
               <li>
                   písomne a z obsahu jej žiadosti vyplýva, že uplatňuje svoje právo, žiadosť sa považuje za podanú podľa zákona o ochrane osobných údajov; žiadosť podanú elektronickou poštou alebo faxom dotknutá osoba doručí písomne najneskôr do troch dní odo dňa jej odoslania,
               </li>
                <li>
                    osobne ústnou formou do zápisnice, z ktorej musí byť zrejmé, kto právo uplatnil, čoho sa domáha a kedy a kto vyhotovil zápisnicu, jeho podpis a podpis dotknutej osoby; kópiu zápisnice je spoločnosť povinná odovzdať dotknutej osobe,
                </li>
                <li>
                    u sprostredkovateľa podľa písmena a) alebo písmena b), je ten povinný túto žiadosť alebo zápisnicu odovzdať spoločnosti bez zbytočného odkladu.
                </li>
            </ol>
            <p>
                Dotknutá osoba pri podozrení, že jej osobné údaje sa neoprávnene spracúvajú, môže podať návrh na začatie konania o ochrane osobných údajov na Úrad na ochranu osobných údajov Slovenskej republiky, so sídlom Hraničná 12, 820 07 Bratislava 27, Slovenská republika alebo kontaktovať úrad prostredníctvom jeho webového sídla http://www.dataprotection.gov.sk.

                Ak dotknutá osoba nemá spôsobilosť na právne úkony v plnom rozsahu, jej práva môže uplatniť zákonný zástupca.

                Ak dotknutá osoba nežije, jej práva, ktoré mala podľa tohto zákona, môže uplatniť blízka osoba.

                Žiadosť dotknutej osoby podľa zákona o ochrane osobných údajov vybaví spoločnosť bezplatne.

                Žiadosť dotknutej osoby podľa zákona o ochrane osobných údajov vybaví spoločnosť bezplatne, okrem úhrady vo výške, ktorá nemôže prekročiť výšku účelne vynaložených vecných nákladov spojených so zhotovením kópií, so zadovážením technických nosičov a s odoslaním informácie dotknutej osobe, ak osobitný zákon neustanovuje inak.

                Spoločnosť je povinná písomne vybaviť žiadosť dotknutej osoby podľa zákona o ochrane osobných údajov najneskôr do 30 dní odo dňa doručenia žiadosti.

                Obmedzenie práv dotknutej osoby podľa zákona o ochrane osobných údajov spoločnosť bez zbytočného odkladu písomne oznámi dotknutej osobe a Úradu na ochranu osobných údajov Slovenskej republiky.

                Spoločnosť Vás, ako dotknutú osobu týmto informovala o ochrane Vašich osobných údajov a poučila Vás o Vašich právach vo vzťahu k ochrane osobných údajov v rozsahu tejto písomnej informačnej povinnosti.

            </p>
        </div>
    </div>
</template>
<script>
    import Header from '../components/Header';
    export default {
        name: 'GDPR',
        components: {
            Header
        },
        created(){
            document.title = 'GDPR | k2flora'
        }
    }
</script>
<style scoped>
    .wrapper{
        width: 940px;
        margin: 0 auto;
        padding: 20px;
    }
    @media screen and (max-width: 980px) {
        .wrapper{
            width: auto;
        }
    }
</style>
