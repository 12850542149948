<template>
    <div>
        <Header text="Produkty" :image="require('@/assets/products/products.jpg')" />
        <div class="wrapper">
            <div class="description">
                Komplexná ponuka služieb od návrhu, projektovania až po realizáciu a údržbu našich systémov.
                Do interiéru aj exteriéru, na mieru aj z komponentov. S nami dáme prírode v architektúre:

                <div class="item">
                    <div>
                        <img src="../assets/check.svg" width="20">
                    </div>
                    <div class="item-text">
                        Zameranie na detaily s komplexnou projekčnou podporou.
                    </div>
                </div>

                <div class="item">
                    <div>
                        <img src="../assets/check.svg" width="20">
                    </div>
                    <div class="item-text">
                        Ekologické riešenia.
                    </div>
                </div>

                <div class="item">
                    <div>
                        <img src="../assets/check.svg" width="20">
                    </div>
                    <div class="item-text">
                        Lokalitný program s ohľadom na odborný návrh a výber rastlín.
                    </div>
                </div>

                <div class="item">
                    <div>
                        <img src="../assets/check.svg" width="20">
                    </div>
                    <div class="item-text">
                        Ekonomické a efektívne riešenia, ktoré šetria prevádzkové náklady stavieb.
                    </div>
                </div>

                <div class="item">
                    <div>
                        <img src="../assets/check.svg" width="20">
                    </div>
                    <div class="item-text">
                        Návrhy a odborná realizácia.
                    </div>
                </div>

                <div class="item">
                    <div>
                        <img src="../assets/check.svg" width="20">
                    </div>
                    <div class="item-text">
                        Údržba a komplexný servis.
                    </div>
                </div>
            </div>
            <div v-if="products" class="products">

                <router-link v-for="product in products" :key="product.id" :to="'/produkt/'+product.id">
                    <div class="product" :style="{ backgroundImage: 'url('+getResolutionImg(product.attributes.image.data.attributes.formats)+')'}">
                        <div class="overlay">
                            <div style="padding: 37px 27px; width: auto; position: absolute; bottom: 0; ">
                                {{ product.attributes.name }}
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
            <div v-else>
                <img src="../assets/loading.svg">
            </div>
        </div>
        <div class="contact-us">
            <h2>
                Kontaktujte nás
            </h2>
            <a href="mailto:k2flora@k2flora.sk">k2flora@k2flora.sk</a><br />
            <a href="tel:+421 902 434 766">+421 902 434 766</a><br />
        </div>
    </div>
</template>
<script>
    import Header from "../components/Header";
    import axios from "axios";
    export default {
        name: 'Products',
        components:{
            Header
        },
        data(){
            return{
                products: [],
            }
        },
        methods: {
            getProducts() {
                axios.get('https://k2flora-cms.herokuapp.com/api/categories')
                .then(response => {
                    this.products = response.data.data;
                });
            },
            getResolutionImg(sizes) {
                if(sizes.large) {
                    return sizes.large.url;
                }
                return sizes.medium.url;
            }
        },
        created(){
            this.getProducts();
            document.title = 'Produkty | k2flora'
        }
    }
</script>
<style scoped>
    .wrapper{
        width:auto;
        padding: 60px 20px;
        background-color: #F8F9FD;
    }
    .products, .description{
        width: 940px;
        margin: 0 auto;
        display: flex;
    }
    .products{
        flex-wrap: wrap;
        gap: 42px;
        margin-top: 42px;
        justify-content: space-between;
    }
    .description{
        display: block;
        font-size: 16px;
        line-height: 22px;
    }
    .item{
        margin-top: 20px;
        display:flex;
    }
    .item-text{
        margin-left: 30px;
    }
    .item-text::first-letter{
        font-size: 20px;
        font-weight: 600;
    }
    .product{
        position: relative;
        display: block;
        font-size: 32px;
        color: #fff;
        font-weight: 600;
        width: 285px;
        height: 327px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;
        -webkit-box-shadow: 0px 4px 50px 5px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 4px 50px 5px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 4px 50px 5px rgba(0, 0, 0, 0.25);
        -moz-transition: box-shadow 0.5s ;
        -webkit-transition: box-shadow 0.5s ;
        transition: box-shadow 0.5s ;
        border-radius: 30px;
    }
    .product:hover{
        -webkit-box-shadow: 0px 4px 50px 5px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0px 4px 50px 5px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 4px 50px 5px rgba(0, 0, 0, 0.5);
        cursor: pointer;
    }
    .contact-us{
        width: auto;
        color: #fff;
        text-align: center;
        padding: 60px 20px;
        font-weight: 600;
        background-color: #349937;
    }
    .contact-us a{
        font-size: 20px;
        line-height: 32px;
        color:white;
    }
    .contact-us a:visited{
        color:white;
    }
    .contact-us a:hover{
        text-decoration: none;
    }
    .contact-us h2{
        font-size: 32px;
        line-height: 44px;
    }

    @media screen and (max-width: 980px) {
        .products, .description{
            width: auto;
        }
        .products{
            display: flex;
            flex-direction: column;
            width: auto;
        }
        .product{
            width: auto;
        }
        .product:not(:first-child){
            margin-top: 42px;
        }
    }
    @media screen and (max-width: 780px) {


    }

</style>
