const state = {
    carousel:[
        {
            "url":require('@/assets/products/vegetacne-fasady/vegetacne-kose.jpg'),
            "name":"Vegetačné fasády",
            "description":"Nevšedné riešenia pre vaše projekty. Vegetačné fasády podporujú trvalo udržateľný " +
                "trend života v mestách. Produkujú kyslík, pohlcujú prach a hluk",
            "routerLink":"vegetacne-fasady"
        },
        {
            "url":require('@/assets/homepage/vegetacne-strechy.jpg'),
            "name":"Vegetačné strechy",
            "description":"Vytvárame aktívne strešné záhrady. Vegetačné strechy znižujú prevádzkové náklady na " +
                "chod budovy. V lete chladia a v zime hrejú.",
            "routerLink":"vegetacne-strechy"
        },
        {
            "url":require('@/assets/products/korenove-cisticky.jpg'),
            "name": "Koreňové čističky",
            "description":"Ekologické riešenia pre úpravu a opätovné využitie odpadových vôd. Vertikálne aj " +
                "horizontálne systémy, ktoré uzatvárajú komplexnosť riešenia zelenej architektúry.",
            "routerLink":"korenove-cisticky"
        },
        {
            "url":require('@/assets/homepage/popinave-systemy.jpg'),
            "name":"Popínavé systémy",
            "description":"Nenáročné riešenia, s bohatým efektom zelene a kvetov. So správnym návrhom a " +
                "výberom rastlín dokážu aj v stiesnených mestských podmienkach vytvoriť zelené oázy.",
            "routerLink":"popinave-systemy"
        },
        {
            "url":require('@/assets/homepage/komunitne-zahrady.jpg'),
            "name":"Komunitné záhrady",
            "description":"Komunitné záhrady, na streche či v parku. Spájajú ľudí, učia deti láske k prírode a sú " +
                "aktívnych prvkom socializácie s ekologickým aspektom",
            "routerLink":"komunitne-zahrady"
        },
        {
            "url":require('@/assets/products/zelen-v-interieri.jpg'),
            "name":"Zeleň v interiéri",
            "description":"Naše interiérové vegetačné aj machové steny sú štýlové, funkčné a zaujmú kreatívnym " +
                "návrhom. Okrem toho znižujú stres, zlepšujú náladu a zvlhčujú vzduch.",
            "routerLink":"zelen-v-interieri"
        },
    ],
};
const getters = {
    getCarousel: (state) => state.carousel,
};
const actions = {
    /*async fetchOverview({commit}, data) {
        commit('setIsLoading', true);
        var url = 'get-events?u='+data+'';
        axios.get(url).then((response)=>{
            commit('setEvents', response.data);
            commit('setIsLoading', false);
        }).catch((err)=>{console.log(err);commit('setIsLoading', false);});
    }*/
};
const mutations = {
    setEvents: (state, events) => (state.events = events),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
